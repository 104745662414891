import styles from './InternationalArticleCards.module.scss';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

export const articleQuestionsBrazil = [
  {
    title: 'QuestionBrazil.0.title',
    caption: 'QuestionBrazil.0.caption',
  },
  {
    title: 'QuestionBrazil.1.title',
    caption: 'QuestionBrazil.1.caption',
    // linkText: 'QuestionBrazil.1.linkText',
  },
  {
    title: 'QuestionBrazil.2.title',
    caption: 'QuestionBrazil.2.caption',
    // linkText: 'QuestionBrazil.2.linkText',
  },
];

export const articleQuestionsItaly = [
  {
    title: 'QuestionItaly.0.title',
    caption: 'QuestionItaly.0.caption',
    linkText: 'QuestionItaly.0.linkText',
    websiteLink: 'QuestionItaly.0.websiteLink',
  },
];

export const articleQuestionsSMB = [
  {
    title: 'QuestionSMB.0.title',
    caption: 'QuestionSMB.0.caption',
    linkText: 'QuestionSMB.0.linkText',
    websiteLink: 'QuestionSMB.0.websiteLink',
  },
];

export const BrazilQuestionSpeedElement = () => {
  const { t } = useTranslation('Overview');
  return (
    <div className={styles['container']}>
      <div className={styles['row']}>
        <div className={styles['box']}>
          <Txt variant="bodySmallBold">{t('oneGB')}</Txt>
          <Txt variant="bodySmallRegular">{t('oneGBPrice')}</Txt>
        </div>
        <div className={styles['box']}>
          <Txt variant="bodySmallBold">{t('fiveGB')}</Txt>
          <Txt variant="bodySmallRegular">{t('fiveGBPrice')}</Txt>
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['box']}>
          <Txt variant="bodySmallBold">{t('tenGB')}</Txt>
          <Txt variant="bodySmallRegular">{t('tenGBPrice')}</Txt>
        </div>
        <div className={styles['box']}>
          <Txt variant="bodySmallBold">{t('twentyGB')}</Txt>
          <Txt variant="bodySmallRegular">{t('twentyGBPrice')}</Txt>
        </div>
      </div>
    </div>
  );
};
