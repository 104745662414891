import React from 'react';

const Illustration1 = () => (
  <svg
    width="248"
    height="160"
    viewBox="0 0 248 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_403:6887)">
      <rect width="248" height="160" fill="white" />
      <path
        opacity="0.1"
        d="M89.7085 7.37206L148.685 32.4913C157.539 36.2622 167.128 37.9904 176.742 37.5475L180.547 37.3722C211.087 35.9651 237.775 57.8081 242.434 88.0237L242.764 90.1599C245.979 111.01 237.265 131.908 220.19 144.299C197.501 160.763 166.154 158.023 146.665 137.871L137.431 128.323C129.009 119.614 117.255 114.94 105.153 115.486L52.6204 117.857C33.0877 118.738 22.5813 95.2339 36.2653 81.2678C41.6484 75.7738 43.6929 67.826 41.6294 60.4163L39.1928 51.6671C30.8096 21.5652 60.96 -4.87238 89.7085 7.37206Z"
        fill="url(#paint0_linear_403:6887)"
      />
      <rect y="143" width="248" height="1" fill="#DEE4E8" />
      <path d="M175.065 72H100.586V79.4933H175.065V72Z" fill="#A2AEBC" />
      <path d="M175.064 72H150.994V79.4933H175.064V72Z" fill="#808D9A" />
      <path
        d="M100.585 79.4934H106.716L96.0436 143.3H94L100.585 79.4934Z"
        fill="#A2AEBC"
      />
      <path
        d="M150.766 79.4934H144.408L155.08 143.3H157.351L150.766 79.4934Z"
        fill="#A2AEBC"
      />
      <path
        d="M174.838 79.4934H168.707L179.379 143.3H181.65L174.838 79.4934Z"
        fill="#808D9A"
      />
      <path
        d="M126.245 79.4934H132.376L121.704 143.3H119.66L126.245 79.4934Z"
        fill="#808D9A"
      />
      <path
        d="M103.374 50.7238C103.374 50.9226 102.977 51.3203 102.977 51.5191L95.0233 61.2617L91.8421 53.5074L87.8655 56.291C87.8655 56.291 90.2515 60.2676 92.2397 63.4489C93.8304 66.0336 95.8187 64.8407 95.8187 64.8407C95.8187 64.8407 97.2104 63.6477 98.4034 62.4547C99.1987 61.6594 100.789 59.2734 101.187 58.677C102.579 56.291 104.567 52.7121 104.567 52.7121C104.766 52.3144 104.965 51.9167 105.164 51.3202C104.368 51.1214 103.971 50.7238 103.374 50.7238Z"
        fill="#BFAA9A"
      />
      <path
        d="M104.566 50.1273C105.56 49.9285 106.157 49.1332 106.355 48.3378C106.554 47.3437 106.753 47.3437 107.747 46.7472C108.543 46.1507 108.344 45.7531 108.344 45.7531L106.554 46.7472C106.554 46.7472 106.355 46.946 105.162 47.7413C103.97 48.5367 103.969 49.332 103.969 49.332C103.969 49.332 103.771 49.9285 103.572 50.5249C103.373 50.9226 103.373 51.1214 103.174 51.3203C103.174 51.5191 102.578 52.5132 102.578 52.5132C102.18 52.7121 104.566 52.7121 104.566 52.7121L105.361 51.3203C106.355 51.7179 108.145 50.9226 108.145 50.9226L107.151 48.7355C107.151 48.7355 109.934 48.7355 110.531 48.7355C111.127 48.5367 111.724 48.3378 111.724 48.139C111.724 47.9402 111.525 47.9402 111.525 47.9402H106.554"
        fill="#8F7A67"
      />
      <path
        d="M106.355 48.3378C106.554 47.3437 106.753 47.3437 107.747 46.7472C108.542 46.1507 108.343 45.7531 108.343 45.7531L106.554 46.7472C106.554 46.7472 106.355 46.946 105.162 47.7413"
        fill="#8F7A67"
      />
      <path
        d="M104.568 50.1275C105.562 49.9287 106.158 49.1333 106.357 48.338C106.357 48.1392 106.357 47.9404 106.556 47.9404"
        fill="#8F7A67"
      />
      <path
        d="M104.568 50.1275C105.562 49.9287 106.158 49.1333 106.357 48.338C106.357 48.1392 106.357 47.9404 106.556 47.9404"
        stroke="#726050"
        strokeWidth="0.0173968"
        strokeMiterlimit="10"
      />
      <path
        d="M109.736 57.2851L103.97 54.7004L109.537 38.794L115.502 39.987L109.736 57.2851Z"
        fill="#C6C7C9"
      />
      <path
        d="M109.737 56.2909L104.567 54.1038L109.538 39.7881L114.906 40.9811L109.737 56.2909Z"
        fill="#1E2021"
      />
      <path
        d="M104.566 50.1273C105.56 49.9285 106.157 49.1332 106.355 48.3378C106.554 47.3437 106.753 47.3437 107.747 46.7472C108.543 46.1507 108.344 45.7531 108.344 45.7531L106.554 46.7472C106.554 46.7472 106.355 46.946 105.162 47.7413C103.97 48.5367 103.969 49.332 103.969 49.332C103.969 49.332 103.771 49.9285 103.572 50.5249C103.373 50.9226 103.373 51.1214 103.174 51.3203C103.174 51.5191 102.578 52.5132 102.578 52.5132C102.18 52.7121 104.566 52.7121 104.566 52.7121L105.361 51.3203"
        fill="#BFAA9A"
      />
      <path
        d="M85.4802 29.4492C85.4802 29.4492 84.8837 29.2504 84.4861 29.4492"
        stroke="#D7E378"
        strokeWidth="0.0296937"
        strokeMiterlimit="10"
      />
      <path
        d="M78.1235 35.0162C78.1235 35.0162 78.5211 39.7881 76.9305 40.7822C76.7316 40.7822 76.334 40.9811 76.1352 40.9811C76.1352 40.9811 75.5387 44.3612 79.7141 44.1623C83.6907 44.1623 82.6965 44.3612 84.486 43.367C86.2754 42.3729 83.6907 40.3846 83.6907 40.3846C83.6907 40.3846 82.1 40.9811 82.6965 36.2092C83.293 31.4373 78.3223 33.0279 78.3223 33.0279L78.1235 35.0162Z"
        fill="#BFAA9A"
      />
      <path
        d="M78.1219 34.6187C78.1219 34.6187 80.1102 37.6011 82.695 37.9988L82.8938 34.221L77.9231 33.8234L78.1219 34.6187Z"
        fill="#AA907D"
      />
      <path
        d="M77.5263 34.0222C78.3216 34.0222 80.5087 37.2035 83.8888 37.0046C86.2748 36.8058 86.8713 32.2327 86.8713 29.4491C86.8713 28.0573 87.269 26.6655 87.269 26.6655L86.6724 25.2737L76.731 25.0749C76.9298 24.876 76.5321 34.0222 77.5263 34.0222Z"
        fill="#BFAA9A"
      />
      <path
        d="M78.3214 21.0982C78.3214 20.8994 78.1226 20.5017 78.1226 20.3029C77.3272 18.9111 75.339 18.5134 73.3507 19.7064C71.5612 20.7005 70.5671 22.6888 71.3624 24.0807C71.5612 24.6771 72.1577 24.876 72.7541 25.2736L72.5553 25.4725C69.5729 29.6479 75.1401 33.8233 76.7307 34.2209C76.7307 34.2209 76.1343 38.794 78.9179 40.1858C78.9179 40.1858 76.5319 37.9987 78.9179 33.8233C81.105 29.6479 78.719 26.4666 78.719 26.4666C78.719 26.4666 83.0933 28.4549 85.4792 26.2678C85.4792 26.2678 87.0699 26.0689 86.871 29.449C86.871 29.449 89.4558 27.0631 89.0582 24.6771C88.6605 21.6947 85.0816 18.7123 78.3214 21.0982Z"
        fill="#FFDB7D"
      />
      <path
        d="M76.9281 31.6361C76.9281 31.8349 76.7293 32.0337 76.5304 32.0337C76.3316 32.0337 75.3375 31.6361 75.1387 30.4431C75.1387 29.6478 75.3375 28.6536 76.1328 28.6536C76.5305 28.6536 76.7293 29.0513 76.7293 29.4489C76.7293 29.8466 76.7293 30.4431 76.7293 30.8407C76.9281 31.2384 76.9281 31.6361 76.9281 31.6361Z"
        fill="#BFAA9A"
      />
      <path
        d="M85.278 33.0277C85.8745 33.0277 85.6757 33.823 85.6757 33.823C85.6757 33.823 85.278 34.6183 84.4827 34.6183C83.6874 34.6183 82.4944 33.4253 82.4944 33.4253C82.4944 33.4253 83.8862 33.0277 84.2839 33.0277C84.4827 33.0277 84.8804 33.2265 84.8804 33.2265C84.8804 33.2265 84.8804 33.0277 85.278 33.0277Z"
        fill="#92625E"
      />
      <path
        d="M85.279 33.0278C85.8755 33.0278 85.6766 33.8231 85.6766 33.8231C85.6766 33.8231 85.279 33.8231 84.4836 33.8231C83.8872 33.8231 82.2965 33.4254 82.4954 33.4254C82.6942 33.4254 83.8872 33.0278 84.2849 33.0278C84.4837 33.0278 84.8813 33.2266 84.8813 33.2266C84.8813 33.2266 84.8814 33.0278 85.279 33.0278Z"
        fill="#7F4F4C"
      />
      <path
        d="M84.8823 32.0337L85.6776 31.636L85.0812 32.2325L84.8823 32.0337Z"
        fill="#8F7A67"
      />
      <path
        d="M76.5313 33.2268C76.5313 33.2268 77.1278 35.6127 74.9407 37.2034"
        stroke="#FFDB7D"
        strokeWidth="0.0704522"
        strokeMiterlimit="10"
      />
      <path
        d="M86.8699 27.8586C86.0746 28.0574 85.2793 28.4551 85.2793 28.4551V28.8527C85.8758 28.4551 86.4722 28.2562 86.8699 28.2562C86.8699 28.0574 86.8699 27.8586 86.8699 27.8586Z"
        fill="#212E39"
      />
      <path
        d="M83.6885 28.6528C83.6885 28.6528 82.2967 27.6586 80.1096 28.454C80.1096 28.454 81.3026 27.261 83.6885 28.2551V28.6528Z"
        fill="#212E39"
      />
      <path
        d="M82.4954 30.2445C82.6052 30.2445 82.6943 30.1555 82.6943 30.0457C82.6943 29.9359 82.6052 29.8468 82.4954 29.8468C82.3856 29.8468 82.2966 29.9359 82.2966 30.0457C82.2966 30.1555 82.3856 30.2445 82.4954 30.2445Z"
        fill="#212E39"
      />
      <path
        d="M86.274 30.0455C86.3838 30.0455 86.4728 29.9565 86.4728 29.8467C86.4728 29.7368 86.3838 29.6478 86.274 29.6478C86.1642 29.6478 86.0752 29.7368 86.0752 29.8467C86.0752 29.9565 86.1642 30.0455 86.274 30.0455Z"
        fill="#212E39"
      />
      <path
        d="M82.2966 29.8469C82.4955 29.6481 82.6943 29.6481 82.6943 29.6481C82.8931 29.6481 83.0919 29.6481 83.0919 29.4493C83.0919 29.6481 82.8931 29.6481 82.6943 29.8469C82.6943 30.0457 82.4955 30.0457 82.2966 29.8469Z"
        fill="#212E39"
      />
      <path
        d="M86.0752 29.6479C86.274 29.4491 86.4728 29.4491 86.4728 29.4491C86.6716 29.4491 86.8705 29.4491 86.8705 29.2502C86.8705 29.4491 86.6716 29.4491 86.4728 29.6479C86.4728 29.8467 86.274 29.8467 86.0752 29.6479Z"
        fill="#212E39"
      />
      <path
        d="M76.73 40.9811C74.3441 41.9752 74.7417 56.6886 74.7417 56.6886L76.9289 62.057L74.1453 69.016C74.1453 69.016 83.2914 71.7996 87.6656 69.4136C87.6656 67.4253 87.4668 55.8932 88.4609 51.7178C88.4609 51.7178 86.2739 41.9752 83.2914 40.5834C84.8821 43.1682 80.7066 44.9576 76.73 40.9811Z"
        fill="#9DD9DD"
      />
      <path
        d="M71.5599 136.419L71.3611 145.963H73.5482L75.5365 135.027L71.5599 136.419Z"
        fill="#BFAA9A"
      />
      <path
        d="M91.446 135.823C91.2471 136.618 94.8261 145.168 94.8261 146.957L96.8144 145.764L95.4226 133.835C95.4226 133.835 91.6448 135.028 91.446 135.823Z"
        fill="#BFAA9A"
      />
      <path
        d="M70.369 136.618H77.9244L79.5151 116.536L81.5033 91.2849L89.2577 136.618H97.2109C95.8191 128.665 91.246 72.5949 87.8659 68.8172L74.3455 68.4195C69.5736 76.1738 69.1759 75.7762 69.7724 116.536C69.7724 122.501 70.1701 129.261 70.369 136.618Z"
        fill="#212E39"
      />
      <path
        opacity="0.6"
        d="M82.1009 85.7175L84.4868 108.782L81.5044 91.2847L82.1009 85.7175Z"
        fill="#131C23"
      />
      <path
        d="M79.7133 68.817C79.7133 70.4077 79.5145 70.8053 78.5203 70.8053C77.725 70.8053 75.9355 70.6065 75.9355 70.6065"
        stroke="#131B21"
        strokeWidth="0.0749477"
        strokeMiterlimit="10"
      />
      <path
        d="M85.2805 69.016C85.2805 69.016 84.8828 71.0043 86.4735 70.8054C86.4735 70.8054 87.8653 70.8054 88.263 70.8054"
        stroke="#131B21"
        strokeWidth="0.0749477"
        strokeMiterlimit="10"
      />
      <path
        d="M82.2966 68.817V78.9573"
        stroke="#131B21"
        strokeWidth="0.0749477"
        strokeMiterlimit="10"
      />
      <path
        d="M83.6884 68.817C83.6884 68.817 84.2849 76.3725 82.2966 76.969"
        stroke="#131B21"
        strokeWidth="0.0749477"
        strokeMiterlimit="10"
      />
      <path
        d="M77.3263 40.7824C77.3263 40.7824 80.1099 45.3555 80.3088 49.7297C80.9052 57.2852 78.5192 71.6009 78.5192 71.6009C78.5192 71.6009 74.7415 73.3904 71.1626 69.8115L74.3439 62.4548L74.5427 56.8876C74.5427 56.8876 71.1626 57.6829 68.9755 56.6887C68.9755 56.6887 68.379 49.5309 69.7708 46.3496C70.7649 43.9637 72.3556 43.566 73.3497 42.9695C74.7415 41.9754 77.3263 40.7824 77.3263 40.7824Z"
        fill="#42C0BD"
      />
      <path
        d="M75.1411 50.5248L74.7434 56.6886"
        stroke="#008085"
        strokeWidth="0.0654576"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M83.2908 40.5834C83.2908 40.5834 87.2673 45.1564 87.665 50.326C88.0626 55.4956 86.8697 71.2031 86.8697 71.2031C86.8697 71.2031 88.858 71.6008 89.0568 70.0102C89.0568 70.0102 88.2615 64.0452 88.4603 61.2616C88.6591 58.478 88.6591 56.0921 88.6591 56.0921L92.8346 54.7003C92.8346 54.7003 91.4428 47.1447 90.8463 45.1565C90.2498 43.1682 87.4662 41.3787 86.2732 41.1799C84.8814 41.1799 83.2908 40.5834 83.2908 40.5834Z"
        fill="#42C0BD"
      />
      <path
        d="M88.8602 55.8931C88.8602 55.8931 90.4508 50.1271 88.6614 45.9517"
        stroke="#008085"
        strokeWidth="0.0654576"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M71.1652 77.5658L73.3523 77.7647L72.7558 65.2384L74.3465 56.6888C74.3465 56.6888 70.3699 57.0864 69.3757 56.6888C69.3757 59.4724 69.1769 63.2501 69.3757 65.6361C69.3757 67.8232 71.1652 77.5658 71.1652 77.5658Z"
        fill="#BFAA9A"
      />
      <path
        d="M75.1389 80.5479C75.1389 80.5479 75.5365 81.1444 75.7354 81.542C75.7354 81.7408 75.9342 82.735 75.9342 82.9338C75.9342 83.1327 75.9342 83.5303 75.9342 83.928C75.9342 84.3257 76.3319 84.7233 75.7354 84.7233C75.1389 84.7233 74.9401 81.1444 75.1389 80.9456C75.1389 80.7467 75.1389 80.7467 75.1389 80.5479C75.1389 80.7467 74.9401 80.349 75.1389 80.5479Z"
        fill="#BFAA9A"
      />
      <path
        d="M70.9651 75.5774C70.9651 75.5774 71.1639 79.1564 71.1639 79.7529C71.1639 80.3494 71.1639 82.1388 71.1639 82.9341C71.1639 82.9341 71.5616 84.7236 71.5616 84.9224C71.7604 85.1213 71.9592 85.5189 72.1581 85.7177C72.3569 85.7177 72.5557 85.7177 72.5557 85.7177C72.5557 85.7177 72.9534 86.3142 73.5498 86.3142C73.5498 86.3142 73.7487 86.7119 73.9475 86.7119C74.1464 86.7119 74.3452 86.9107 74.3452 86.9107C74.3452 86.9107 74.544 86.9107 74.7428 86.7119C74.7428 86.5131 74.3452 85.9166 74.1464 85.3201L73.9475 84.1271V83.7295C73.9475 83.7295 74.1464 83.7294 74.1464 84.1271L74.3452 85.1212L74.544 86.1154C74.544 86.3142 74.7428 87.3084 74.9417 87.5072C75.1405 87.5072 75.3394 87.3083 75.3394 87.1095C75.3394 86.9107 75.1405 85.9166 75.1405 85.7177C75.1405 85.5189 74.9417 84.7236 74.9417 84.3259C74.9417 83.9283 74.7428 83.133 74.7428 82.9341C74.7428 82.7353 74.544 81.5423 74.544 81.3435C74.544 81.1446 73.5499 79.7529 73.3511 79.554C73.1522 79.3552 72.7545 78.1622 72.7545 75.9751C73.351 73.3903 71.5616 75.3786 70.9651 75.5774Z"
        fill="#BFAA9A"
      />
      <path
        d="M73.7487 86.1152C73.7487 86.1152 73.5499 85.7176 73.3511 85.3199C73.3511 84.9223 73.1522 84.3258 73.1522 84.127C73.1522 83.9281 72.9534 83.3316 72.9534 83.3316"
        stroke="#8F7A67"
        strokeWidth="0.0371439"
        strokeMiterlimit="10"
      />
      <path
        d="M72.7537 85.5191C72.7537 85.5191 72.5548 85.3203 72.5548 84.9226C72.5548 84.525 72.3561 84.1273 72.3561 83.9285C72.3561 83.7297 72.1572 83.1331 72.1572 83.1331"
        stroke="#8F7A67"
        strokeWidth="0.0371439"
        strokeMiterlimit="10"
      />
      <path
        d="M75.7361 84.7235C75.7361 84.7235 75.3385 81.9398 75.1396 81.3434"
        stroke="#8F7A67"
        strokeWidth="0.0371439"
        strokeMiterlimit="10"
      />
      <path
        d="M65.1988 150.735C69.1754 151.332 71.5613 150.735 71.5613 150.735V149.343L72.9532 147.753V150.536H75.1403L75.3392 146.162L71.7602 147.951L68.9766 149.542L65 149.343L65.1988 150.735Z"
        fill="#212E39"
      />
      <path
        d="M65.5973 149.542C65.5973 149.542 69.7727 150.536 70.9657 149.94C71.761 148.946 72.7552 147.951 73.1529 147.753C73.5505 147.554 75.5388 146.361 75.5388 146.361C75.5388 146.361 75.7376 145.764 75.7376 145.168C75.7376 144.571 74.7435 143.18 74.7435 143.18L74.5446 141.191H70.9657C70.9657 141.191 69.3751 145.566 68.5797 146.957C67.5856 148.747 66.1938 147.952 65.7962 148.349C65.3985 148.946 65.5973 149.542 65.5973 149.542Z"
        fill="#064656"
      />
      <path
        d="M68.7786 146.759H69.7727"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M69.1746 145.963H70.1687"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M69.3728 145.367H70.3669"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M69.7727 144.571H70.7669"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M69.9707 143.975H70.9649"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M70.3669 143.18H71.3611"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M70.5688 142.583H71.563"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M102.181 150.735C98.204 151.332 95.818 150.735 95.818 150.735V149.343L94.4262 147.753V150.536H92.2391L92.0403 146.162L95.6192 147.951L98.4028 149.542L102.379 149.343L102.181 150.735Z"
        fill="#212E39"
      />
      <path
        d="M101.982 149.542C101.982 149.542 97.8069 150.536 96.6139 149.94C95.8186 148.946 94.8245 147.951 94.4268 147.753C94.0291 147.554 92.0409 146.361 92.0409 146.361C92.0409 146.361 91.842 145.764 91.842 145.168C92.0409 144.571 92.8362 143.18 92.8362 143.18L93.035 141.191L96.6139 141.39C96.6139 141.39 98.2046 145.764 98.9999 147.156C99.9941 148.946 101.386 148.15 101.784 148.548C101.982 148.946 101.982 149.542 101.982 149.542Z"
        fill="#064656"
      />
      <path
        d="M98.6033 146.759H97.6091"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M98.4019 146.162H97.209"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M98.0051 145.367H97.011"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M97.808 144.571H96.615"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M97.4109 143.975H96.4167"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M97.2098 143.18H96.0168"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M96.813 142.583H95.8188"
        stroke="#212E39"
        strokeWidth="0.1184"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M138.625 70.5802H161.975C162.528 70.5802 162.942 70.1657 162.942 69.613L161.284 54.9672C161.284 54.4145 160.87 54 160.317 54H136.967C136.414 54 136 54.4145 136 54.9672L137.658 69.613C137.658 70.1657 138.072 70.5802 138.625 70.5802Z"
        fill="#F1F0F5"
        stroke="#9FAFBC"
        strokeWidth="0.4231"
        strokeMiterlimit="10"
      />
      <path
        d="M138.073 72.0999H173.306C173.583 72.0999 173.721 71.9618 173.721 71.6854V71.2709C173.721 70.9946 173.583 70.8564 173.306 70.8564H138.073C137.797 70.8564 137.659 70.9946 137.659 71.2709V71.6854C137.659 71.9618 137.797 72.0999 138.073 72.0999Z"
        fill="#C2CCD4"
      />
      <path
        opacity="0.5"
        d="M137.936 72.0999H152.167V70.8564H137.936C137.798 70.8564 137.659 70.9946 137.659 71.1328V71.8236C137.659 71.9618 137.798 72.0999 137.936 72.0999Z"
        fill="#DEE4E8"
      />
      <path
        d="M138.902 56.9015H158.799C159.075 56.9015 159.213 56.7634 159.213 56.487C159.213 56.2107 159.075 56.0725 158.799 56.0725H138.902C138.626 56.0725 138.488 56.2107 138.488 56.487C138.488 56.7634 138.626 56.9015 138.902 56.9015Z"
        fill="#064751"
      />
      <path
        d="M153.687 62.7046H159.766L159.214 58.145H153.134L153.687 62.7046Z"
        fill="#DEE3E7"
      />
      <path
        d="M141.666 67.8169H160.457L160.043 63.3955H141.114L141.666 67.8169Z"
        fill="#C2CCD4"
      />
      <path
        d="M146.641 62.7046H152.72L152.168 58.145H145.95L146.641 62.7046Z"
        fill="#DEE3E7"
      />
      <path
        d="M34.5246 111.009C34.5246 111.009 29.9841 85.4475 30.4886 71.9941C31.1613 49.4596 35.1973 46.9371 35.1973 46.9371C35.1973 46.9371 36.3745 80.5706 35.87 93.8558C35.3655 107.141 36.5426 119.922 37.2153 124.63L34.5246 111.009Z"
        fill="#024656"
      />
      <path
        d="M37.7197 135.897C37.7197 135.897 37.047 124.126 34.5245 115.045C32.002 106.132 25.948 93.5192 24.2663 76.198C22.5846 58.8767 25.948 56.0179 25.948 56.0179C25.948 56.0179 30.3203 83.7655 33.011 99.4051C35.7017 115.045 38.056 131.861 38.2242 135.729L37.7197 135.897Z"
        fill="#024656"
      />
      <path
        d="M37.7197 135.897C37.7197 135.897 37.047 124.126 34.5245 115.045C32.002 106.132 25.948 93.5192 24.2663 76.198C22.5846 58.8767 25.948 56.0179 25.948 56.0179C25.948 56.0179 23.7618 69.6394 31.3293 97.3871C35.5335 112.858 38.056 131.861 38.2242 135.729L37.7197 135.897Z"
        fill="#024656"
      />
      <path
        d="M37.3826 135.729C37.3826 135.729 39.4006 119.417 39.9051 104.787C40.5778 90.3241 46.8 78.8888 47.3045 65.2672C47.809 51.6456 43.4367 45.0871 43.4367 45.0871C43.4367 45.0871 44.1093 66.4444 41.2505 79.5614C38.2235 92.5103 38.728 101.76 38.5598 113.531C38.5598 125.303 36.71 135.729 36.71 135.729H37.3826Z"
        fill="#024656"
      />
      <path
        d="M39.5687 135.897C39.5687 135.897 40.2414 115.717 36.7099 102.096C33.3465 89.3151 35.0282 67.6215 39.2324 63.4173C39.2324 63.4173 39.4005 74.3482 39.905 82.4202C40.2414 90.6604 40.7459 121.94 40.2414 135.729L39.5687 135.897Z"
        fill="#024656"
      />
      <path
        d="M39.5673 135.898C39.5673 135.898 40.24 114.877 38.222 101.087C36.0358 84.7747 37.5493 68.7988 39.0628 63.5856C39.0628 63.5856 39.231 74.5165 39.7355 82.5885C40.0718 90.8287 40.5763 122.108 40.0718 135.898H39.5673Z"
        fill="#024656"
      />
      <path
        d="M36.7099 135.897C36.7099 135.897 34.5237 121.099 32.5057 112.354C30.4877 103.609 24.2655 93.5192 21.2385 83.5973C21.2385 83.5973 19.8932 94.5282 23.5929 101.928C27.2925 109.159 30.4877 110.504 33.0102 119.417C35.7009 128.666 36.0372 135.897 36.0372 135.897H36.7099Z"
        fill="#024656"
      />
      <path
        d="M41.0818 135.897C41.0818 135.897 41.9227 127.153 43.9407 117.735C45.9587 108.318 50.6673 105.627 51.6764 92.5103C52.6854 79.3932 50.6674 71.8257 50.6674 71.8257C50.6674 71.8257 45.7905 97.0508 44.7815 104.45C43.7725 111.85 42.7635 120.09 41.7545 126.985C40.7455 133.375 40.241 135.897 40.241 135.897H41.0818Z"
        fill="#024656"
      />
      <path
        d="M41.0825 135.897C41.0825 135.897 41.9233 127.153 43.9413 117.735C45.9593 108.318 50.668 105.627 51.677 92.5103C52.686 79.3932 50.668 71.8257 50.668 71.8257C50.668 71.8257 51.1725 78.8887 50.1635 85.7836C49.3227 92.5103 48.1455 99.7415 46.9683 103.273C44.614 110.336 42.7642 120.09 41.587 126.985C40.578 133.375 40.0735 135.897 40.0735 135.897H41.0825Z"
        fill="#024656"
      />
      <path
        d="M26.7874 132.366C26.2829 133.711 25.9465 135.393 25.9465 136.906C25.9465 143.801 30.8234 149.183 36.7092 149.183C42.5951 149.183 47.4719 143.633 47.4719 136.906C47.4719 135.225 47.1356 133.711 46.6311 132.366H26.7874Z"
        fill="#6ACBF3"
      />
      <path
        d="M183.324 117.017L183.521 116.033L168.955 111.702L168.561 112.686L183.324 117.017Z"
        fill="#9FAFBC"
      />
      <path
        d="M168.759 116.623L183.719 113.277L184.309 111.899L168.562 115.639L168.759 116.623Z"
        fill="#9FAFBC"
      />
      <path
        d="M185.882 97.5299H206.943L216.195 55.6038L196.905 55.0133L185.882 97.5299Z"
        fill="#677A88"
      />
      <path
        d="M194.934 116.82L207.728 113.08L207.531 112.096L194.737 115.836L194.934 116.82Z"
        fill="#9FAFBC"
      />
      <path
        d="M207.531 116.426L207.728 115.245L194.934 112.489L194.737 113.473L207.531 116.426Z"
        fill="#9FAFBC"
      />
      <path
        d="M214.373 55.2071L217.252 55.8608L207.577 98.4747L204.698 97.821L214.373 55.2071Z"
        fill="#9FAFBC"
      />
      <path
        d="M164.819 80.7992H188.636V83.3581H164.819V80.7992Z"
        fill="#677A88"
      />
      <path
        d="M164.622 81.7833H166.197V97.5302H164.622V81.7833Z"
        fill="#677A88"
      />
      <path
        d="M214.62 142.999H216.588L206.55 99.105L202.81 100.089L214.62 142.999Z"
        fill="#9FAFBC"
      />
      <path
        d="M161.67 143H159.504L169.543 99.1051L173.283 100.089L161.67 143Z"
        fill="#677A88"
      />
      <path
        d="M195.922 143H197.89L193.953 99.1051L190.213 100.089L195.922 143Z"
        fill="#677A88"
      />
      <path
        d="M182.927 143H180.761L184.698 99.1051L188.438 100.089L182.927 143Z"
        fill="#9FAFBC"
      />
      <path
        d="M208.125 96.1525H162.065V100.286H207.337L208.125 96.1525Z"
        fill="#9FAFBC"
      />
      <path
        d="M184.505 96.9398L187.26 97.5303L197.889 55.2106L194.937 54.6201L184.505 96.9398Z"
        fill="#9FAFBC"
      />
      <path
        d="M165.287 64.8804V64.6835C165.484 64.6835 165.484 64.6835 165.681 64.6835L181.034 65.8646L188.12 55.8259L193.041 60.7468C193.041 60.7468 182.412 69.9981 181.625 69.8013C180.64 69.6045 166.468 66.8487 165.484 66.6519C165.287 66.0614 165.287 65.4709 165.287 64.8804Z"
        fill="#8F7A67"
      />
      <path
        d="M158.988 66.6521C158.595 67.4394 158.004 69.4078 158.004 69.4078C158.004 69.4078 158.398 70.1951 158.988 69.211C159.382 68.2268 160.169 66.8489 160.169 66.6521C159.972 66.2584 158.988 66.4553 158.988 66.6521Z"
        fill="#8F7A67"
      />
      <path
        d="M158.988 69.6048C158.988 69.6048 159.381 69.8016 159.578 69.2111C159.381 69.6048 159.972 69.6048 159.972 69.2111C159.972 69.2111 160.365 66.8491 161.153 66.6522C161.743 66.4554 159.381 67.2428 158.791 67.4396C158.594 67.6364 158.988 69.6048 158.988 69.6048Z"
        fill="#8F7A67"
      />
      <path
        d="M158.984 69.0142C159.181 68.8174 159.575 67.6364 159.968 67.0459"
        stroke="#726050"
        strokeWidth="0.0199181"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M158.396 68.0301C158.986 67.6364 159.577 67.4396 159.577 67.4396C159.577 67.4396 160.167 67.2427 160.758 67.2427"
        stroke="#726050"
        strokeWidth="0.0298771"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M159.579 69.2111C159.579 69.0142 159.776 67.4395 160.169 67.0459"
        stroke="#726050"
        strokeWidth="0.0199181"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M156.426 68.4235C156.426 68.4235 158.001 67.0457 158.788 66.4551C159.576 65.8646 162.135 64.8805 162.922 64.6836C163.512 64.4868 164.89 64.4868 165.875 64.4868V66.652C165.678 66.652 165.481 66.652 165.481 66.652C165.284 66.652 165.087 66.652 164.89 66.652C164.3 67.2425 164.103 67.833 163.906 68.4235C163.709 69.014 163.512 69.014 162.922 69.6045C162.331 70.3919 161.741 71.376 161.741 71.376C161.741 71.376 161.15 70.5887 161.741 69.8014C162.332 69.014 162.331 68.2267 162.331 68.2267C162.331 66.652 160.166 67.2425 160.166 67.2425C160.166 67.2425 158.788 68.0298 157.607 68.6203C156.23 69.4077 156.426 68.4235 156.426 68.4235Z"
        fill="#8F7A67"
      />
      <path
        d="M199.336 46.3777C199.336 46.3777 198.943 50.1176 199.927 51.4955C200.32 52.086 201.305 52.4796 201.108 52.8733C200.32 53.8575 197.762 55.2354 197.762 55.2354C197.762 55.2354 195.006 55.4322 193.234 54.448C192.25 53.8575 192.644 53.0702 193.037 52.2828C193.431 51.6923 194.415 51.6923 194.809 50.905C195.006 50.3145 195.006 49.5271 195.006 47.7556C194.612 43.2284 199.139 44.803 199.139 44.803L199.336 46.3777Z"
        fill="#8F7A67"
      />
      <path
        d="M199.536 45.7874C199.536 45.7874 197.568 48.7399 195.206 49.1336L195.009 45.5905L199.733 45.1969L199.536 45.7874Z"
        fill="#726050"
      />
      <path
        d="M200.125 45.1968C199.338 45.1968 197.369 48.1493 194.023 47.9525C191.661 47.7557 191.267 43.4253 191.267 40.6696C191.267 39.2917 190.874 38.1107 190.874 38.1107L191.464 36.7328L200.912 36.536C200.716 36.536 200.912 45.3936 200.125 45.1968Z"
        fill="#8F7A67"
      />
      <path
        d="M199.339 32.993C199.339 32.7961 199.536 32.4025 199.536 32.2056C200.323 30.8278 202.292 30.6309 204.063 31.6151C205.835 32.5993 206.622 34.3708 206.031 35.7487C205.835 36.3392 205.244 36.536 204.654 36.7328L204.85 36.9297C207.606 40.8664 202.292 44.8031 200.914 45.1968C200.914 45.1968 201.504 49.5272 198.749 50.905C198.749 50.905 200.914 48.7398 198.945 44.8031C196.78 40.6696 199.142 37.717 199.142 37.717C199.142 37.717 195.009 39.6854 192.647 37.5202C192.647 37.5202 191.072 37.3234 191.269 40.6696C191.269 40.6696 188.907 38.5044 189.3 36.1423C189.497 33.3866 192.843 30.6309 199.339 32.993Z"
        fill="#212E39"
      />
      <path
        d="M200.718 43.2284C200.718 43.4252 200.915 43.622 201.112 43.622C201.308 43.622 202.292 43.2284 202.292 42.0473C202.292 41.26 202.096 40.4727 201.308 40.2758C201.111 40.2758 200.718 40.6695 200.718 41.0632C200.718 41.4568 200.718 42.0473 200.718 42.441C200.718 42.6378 200.718 43.0315 200.718 43.2284Z"
        fill="#8F7A67"
      />
      <path
        d="M192.647 44.4095C192.056 44.4095 192.253 45.1968 192.253 45.1968C192.253 45.1968 192.647 45.9842 193.434 45.9842C194.221 45.9842 195.402 44.8032 195.206 44.8032C195.206 44.8032 193.828 44.4095 193.631 44.4095C193.434 44.4095 193.04 44.6063 193.04 44.6063C193.04 44.6063 193.04 44.4095 192.647 44.4095Z"
        fill="#92625E"
      />
      <path
        d="M192.648 44.4093C192.057 44.4093 192.254 45.1967 192.254 45.1967C192.254 45.1967 192.648 45.1967 193.238 45.1967C193.829 45.1967 195.207 44.803 195.207 44.803C195.207 44.803 193.829 44.4093 193.632 44.4093C193.435 44.4093 193.041 44.6062 193.041 44.6062C193.041 44.6062 193.041 44.4093 192.648 44.4093Z"
        fill="#7F4F4C"
      />
      <path
        d="M192.646 40.2759V39.8822C192.646 39.8822 190.677 38.898 190.087 39.4885C190.087 39.4885 191.268 39.6854 192.646 40.2759Z"
        fill="#212E39"
      />
      <path
        d="M194.223 40.2759C194.223 40.2759 195.601 39.2917 197.57 40.0791C197.57 40.0791 196.389 38.8981 194.223 39.8822V40.2759Z"
        fill="#212E39"
      />
      <path
        d="M195.601 43.2286C194.731 43.2286 194.026 42.5236 194.026 41.6539C194.026 40.7842 194.731 40.0792 195.601 40.0792C196.471 40.0792 197.176 40.7842 197.176 41.6539C197.176 42.5236 196.471 43.2286 195.601 43.2286Z"
        stroke="#D7E378"
        strokeWidth="0.057561"
        strokeMiterlimit="10"
      />
      <path
        d="M191.465 42.8348C190.595 42.8348 189.89 42.1298 189.89 41.2601C189.89 40.3905 190.595 39.6855 191.465 39.6855C192.335 39.6855 193.04 40.3905 193.04 41.2601C193.04 42.1298 192.335 42.8348 191.465 42.8348Z"
        stroke="#D7E378"
        strokeWidth="0.057561"
        strokeMiterlimit="10"
      />
      <path
        d="M193.04 43.4252L192.252 43.0315L192.843 43.622L193.04 43.4252Z"
        fill="#726050"
      />
      <path
        d="M195.404 41.457C195.207 41.2601 195.207 41.2601 195.01 41.2601C194.814 41.2601 194.617 41.2601 194.617 41.0633C194.617 41.2601 194.814 41.2601 195.01 41.457C195.01 41.457 195.207 41.457 195.404 41.457Z"
        fill="#212E39"
      />
      <path
        d="M191.86 41.0635C191.664 40.8667 191.663 40.8667 191.467 40.8667C191.27 40.8667 191.073 40.8667 191.073 40.6698C191.073 40.8667 191.27 40.8667 191.467 41.0635C191.467 41.2603 191.664 41.2603 191.86 41.0635Z"
        fill="#212E39"
      />
      <path
        d="M193.04 41.457C193.04 41.457 193.63 41.2602 194.024 41.457"
        stroke="#D7E378"
        strokeWidth="0.0287805"
        strokeMiterlimit="10"
      />
      <path
        d="M200.915 44.6065C200.718 45.3939 200.718 45.9844 201.112 46.7717C201.308 47.3622 201.899 47.9527 202.489 48.3464C201.899 47.9527 201.505 47.3622 201.112 46.7717C200.915 45.9844 200.718 45.197 200.915 44.6065Z"
        fill="#212E39"
      />
      <path
        d="M201.112 52.0859C202.687 53.0701 204.261 54.4479 205.245 55.2353C207.017 56.6131 207.411 57.7941 207.411 58.7783C207.411 60.1562 207.017 61.7309 206.23 63.5024C204.458 69.6043 201.702 76.2967 201.702 77.2809C201.899 78.8556 190.089 79.2493 190.089 74.722C190.089 72.1632 187.727 69.4075 187.727 64.8802C187.727 64.8802 182.609 62.9119 182.806 62.5182C183.003 62.1245 186.94 55.2353 186.94 55.2353C189.499 51.1017 193.829 51.889 193.829 51.889C191.861 54.6448 196.782 56.4163 201.112 52.0859Z"
        fill="#D7E378"
      />
      <path
        d="M175.719 114.089C176.704 114.68 180.05 124.915 181.231 126.293L178.672 126.883L170.798 115.467C170.798 115.467 174.932 113.499 175.719 114.089Z"
        fill="#8F7A67"
      />
      <path
        d="M178.082 127.277L180.05 131.805C180.05 131.805 179.657 135.741 180.444 135.938L184.381 124.128C183.594 123.931 180.444 124.522 180.444 124.522L179.854 126.096L178.082 127.277Z"
        fill="#054752"
      />
      <path
        d="M185.538 124.545L181.594 136.302L180.475 135.926L184.418 124.169L185.538 124.545Z"
        fill="#212E39"
      />
      <path
        d="M179.459 130.427L180.247 129.836"
        stroke="#212E39"
        strokeWidth="0.136"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M179.261 129.442L179.851 128.852"
        stroke="#212E39"
        strokeWidth="0.136"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M178.866 128.656L179.654 128.065"
        stroke="#212E39"
        strokeWidth="0.136"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M149.935 103.657C149.739 104.641 141.472 111.531 140.684 113.302L139.306 111.137L146.983 99.5237C146.983 99.5237 150.132 102.673 149.935 103.657Z"
        fill="#8F7A67"
      />
      <path
        d="M138.911 110.547L134.974 113.499C134.974 113.499 131.037 114.09 131.037 114.877L143.438 115.861C143.438 114.877 142.257 112.121 142.257 112.121L140.485 111.924L138.911 110.547Z"
        fill="#054752"
      />
      <path
        d="M143.356 117.066L131.001 115.994L131.103 114.817L143.458 115.889L143.356 117.066Z"
        fill="#212E39"
      />
      <path
        d="M136.158 112.515L136.946 113.105"
        stroke="#212E39"
        strokeWidth="0.136"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M136.942 111.924L137.729 112.515"
        stroke="#212E39"
        strokeWidth="0.136"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M137.729 111.334L138.517 112.121"
        stroke="#212E39"
        strokeWidth="0.136"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M163.123 82.9894C157.218 83.5799 145.408 100.705 142.652 104.445L149.738 110.546L163.91 92.8312L192.058 96.1775C192.058 96.1775 201.506 96.5711 202.096 89.8787C202.884 83.1863 201.703 78.4622 201.703 78.4622L202.096 76.1002L189.302 74.5255L189.499 76.6907L184.972 81.0211C176.704 81.8084 165.485 82.5958 163.123 82.9894Z"
        fill="#42C0BD"
      />
      <path
        d="M187.531 64.8802C187.334 62.9118 188.122 58.1877 190.287 56.0226"
        stroke="#8DC03F"
        strokeWidth="0.0691579"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M201.701 78.8555L189.694 76.6903"
        stroke="#048086"
        strokeWidth="0.0929608"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M196.39 77.8716C195.997 79.6431 196.194 80.0367 197.178 80.4304C198.162 80.6273 200.13 80.8241 200.13 80.8241"
        stroke="#048086"
        strokeWidth="0.0743687"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M190.678 76.8875C190.678 76.8875 190.679 79.0526 188.907 78.659C188.907 78.659 188.317 78.4621 187.923 78.4621"
        stroke="#048086"
        strokeWidth="0.0743687"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M194.618 81.2179C194.618 81.2179 188.91 80.6274 184.973 81.0211"
        stroke="#048086"
        strokeWidth="0.0743687"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M191.862 77.0843C191.862 77.0843 191.271 78.8558 191.074 81.021"
        stroke="#048086"
        strokeWidth="0.0743687"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M193.632 77.4781L192.451 81.0211"
        stroke="#048086"
        strokeWidth="0.0743687"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M164.105 72.5569C164.302 71.9664 164.499 70.5885 165.286 70.5885C165.089 70.1948 165.68 70.1948 165.68 70.1948C165.68 70.1948 167.058 70.1948 167.648 69.998C168.239 69.8012 169.026 70.1948 169.026 70.1948C169.026 70.1948 170.798 70.7853 171.782 70.9822C172.766 71.179 176.9 71.5727 176.9 71.5727C176.9 71.5727 179.458 73.541 176.309 73.1474C173.356 73.3442 171.388 74.1316 171.388 74.1316C170.798 74.3284 169.223 73.7379 169.223 73.7379L166.861 73.1474C166.861 73.1474 165.286 73.7379 165.089 73.7379C164.893 73.7379 164.696 73.7379 164.696 73.7379C164.696 73.7379 164.499 73.9347 164.302 73.7379C163.712 73.7379 164.105 72.5569 164.105 72.5569ZM165.089 73.1474L166.467 72.5569C166.467 72.5569 165.877 72.36 165.877 72.5569C165.483 72.36 165.089 73.1474 165.089 73.1474Z"
        fill="#8F7A67"
      />
      <path
        d="M167.451 70.7852L165.286 70.5883"
        stroke="#726050"
        strokeWidth="0.0179007"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M167.254 71.3758L165.679 71.1789C165.679 71.1789 165.088 71.1789 164.892 71.3758L164.301 72.1631L163.907 72.9505"
        stroke="#726050"
        strokeWidth="0.0179007"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M167.649 72.5568H166.468C166.468 72.5568 164.5 73.541 164.5 73.7378C164.5 73.9346 164.697 74.1315 164.697 74.1315"
        stroke="#726050"
        strokeWidth="0.0179007"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M172.766 73.5411C172.569 72.9506 174.144 71.5727 174.538 71.3759H190.088L195.008 65.0771L199.536 69.0139C199.536 69.0139 192.056 75.5095 191.465 75.5095C190.481 75.5095 174.341 73.9348 174.341 73.9348C174.341 73.5411 172.963 73.5411 172.766 73.5411Z"
        fill="#8F7A67"
      />
      <path
        d="M201.311 57.2038C201.311 57.2038 196.98 61.3373 194.815 64.8804L199.736 69.2108C199.736 69.2108 204.066 66.2582 206.035 62.7152"
        stroke="#8DC03F"
        strokeWidth="0.0691579"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M190.484 95.9806L171.194 98.5395L180.642 118.223L174.343 121.963L160.565 99.9173C160.565 99.9173 158.99 96.5711 160.565 93.4217C160.761 92.8312 162.73 91.2565 166.273 92.0439C169.029 92.6344 172.572 93.4217 175.13 93.8154C183.594 95.1933 190.484 95.9806 190.484 95.9806Z"
        fill="#27A1A1"
      />
      <path
        d="M186.082 80.7995H209.899V83.3584H186.082V80.7995Z"
        fill="#9FAFBC"
      />
      <path
        d="M186.08 81.7836H187.654V97.5305H186.08V81.7836Z"
        fill="#9FAFBC"
      />
      <path
        d="M119.569 70.1084H130.324V72.1001H119.569V70.1084Z"
        fill="#465A68"
      />
      <path
        d="M125.745 54.9711V70.1083H124.151V55.7678L125.745 54.9711Z"
        fill="#677A88"
      />
      <path
        d="M131.372 49.4635L131.884 50.0741L117.84 61.8454L117.329 61.2349L131.372 49.4635Z"
        fill="#677A88"
      />
      <path
        d="M119.442 58.9798L120.21 59.8957L117.768 61.9429L117 61.027L119.442 58.9798Z"
        fill="#D7E278"
      />
      <path
        d="M141.08 53.9751L136.3 54.3734L134.308 58.9544C134.308 58.9544 137.694 58.5561 141.08 53.9751Z"
        fill="#BFC7D1"
      />
      <path
        d="M137.095 58.1578C135.775 58.1578 134.705 57.0878 134.705 55.7678C134.705 54.4477 135.775 53.3777 137.095 53.3777C138.415 53.3777 139.485 54.4477 139.485 55.7678C139.485 57.0878 138.415 58.1578 137.095 58.1578Z"
        fill="#D7E278"
      />
      <path
        d="M133.911 48.7967L133.313 48C133.313 48 131.521 48.5975 130.724 49.7926L131.521 50.9876L133.911 48.7967Z"
        fill="#D7E278"
      />
      <path
        d="M133.91 48.7966L134.906 49.9916C135.503 50.39 138.491 50.9875 141.08 54.1742C141.08 54.1742 137.495 55.9668 134.308 59.1536C132.117 55.9668 132.516 52.5809 132.117 51.9833C131.719 51.3858 131.321 50.7883 131.321 50.7883C131.321 50.7883 132.117 49.3941 133.91 48.7966Z"
        fill="#202E39"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_403:6887"
        x1="249.263"
        y1="75"
        x2="-0.737364"
        y2="75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#14B9FF" />
        <stop offset="1" stopColor="#006EAD" />
      </linearGradient>
      <clipPath id="clip0_403:6887">
        <rect width="248" height="160" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Illustration1;
